/** @jsx jsx */
import PropTypes from "prop-types";
import { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { jsx } from "theme-ui";

const FormDatePicker = ({ name, id, required }) => {
  const currentDate = new Date();
  const daysOut = currentDate.setDate(currentDate.getDate() + 10); // adds 10 days to current date

  const [startDate, setStartDate] = useState(daysOut);

  return (
    <div
      sx={{
        ".react-datepicker-wrapper": {
          width: "100%",
        },
        ".react-datepicker__current-month, .react-datepicker__day-name, .react-datepicker__day":
          {
            fontFamily: "body",
          },
        ".react-datepicker__day--selected": {
          backgroundColor: "secondary",
        },
        ".react-datepicker__triangle": {
          left: "50% !important",
        },
      }}
    >
      <DatePicker
        id={id}
        name={name}
        required={required}
        selected={startDate}
        onChange={(date) => setStartDate(date)}
        sx={{
          backgroundColor: "background",
          border: "4px solid transparent",
          borderRadius: 0,
          color: "text",
          display: "block",
          fontSize: [1, null, 3],
          fontWeight: "body",
          lineHeight: "inherit",
          outline: "none",
          px: 3,
          py: 2,
          transition: "all 0.3s",
          width: "100%",
          "&::placeholder": {
            color: "#B5B5B5",
            fontSize: [1, null, 3],
            fontWeight: "body",
          },
          "&:required:focus": {
            border: "4px solid",
            borderColor: "primary",
          },
        }}
      />
    </div>
  );
};

FormDatePicker.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  required: PropTypes.bool,
};

FormDatePicker.defaultProps = {
  required: false,
};

export default FormDatePicker;
