/** @jsx jsx */
import PropTypes from "prop-types";
import React from "react";
import { Input, jsx } from "theme-ui";

const AutoFormatInput = ({
  className,
  id,
  name,
  onChange,
  placeholder,
  required,
  type,
  ...props
}) => {
  const checkIfValidDate = (mm, dd, yyyy) => {
    let correctedDate = `${mm}/${dd}/${yyyy}`; // setting value to input incase testDate fails

    const monthInt = parseInt(mm) - 1,
      dayInt = parseInt(dd),
      yearInt = parseInt(yyyy);

    const testDate = new Date(yearInt, monthInt, dayInt);

    if (!isNaN(testDate)) {
      const fixedMonth = String(testDate.getMonth() + 1).padStart(2, "0");
      const fixedDay = String(testDate.getDate()).padStart(2, "0");
      const fixedYear = testDate.getFullYear();
      correctedDate = `${fixedMonth}/${fixedDay}/${fixedYear}`;
    }

    return correctedDate;
  };

  /**
   * Corrects the mm or dd part of date auto format.
   * Adds starting zeros to mm or dd if needed.
   * And prevents number from being greater than the max num.
   * @param {String} inputStr - either the dd or mm in mm/dd/yyyy
   * @param {Number} maxNum - maximum number the mm or dd should be
   * @returns string
   */
  const checkIfInRange = (inputStr, maxNum) => {
    if (inputStr.charAt(0) !== "0" || inputStr === "00") {
      let inputNum = parseInt(inputStr);

      if (isNaN(inputNum) || inputNum <= 0 || inputNum > maxNum) {
        inputNum = 1;
      }

      const firstNumOfMaxNum = parseInt(maxNum.toString().charAt(0));
      const inputNumStr = inputNum.toString();

      if (inputNum > firstNumOfMaxNum && inputNumStr.length === 1) {
        inputStr = "0" + inputNum;
      } else {
        inputStr = inputNumStr;
      }
    }

    return inputStr;
  };

  /**
   * Coverts string to format mm/dd/yyyy
   * @param {String} inputValue - value of input field
   * @returns string
   */
  const formatDate = (inputValue) => {
    if (!inputValue) return inputValue;
    const cleanString = inputValue.replace(/[^\d]/g, ""); // clean the input for any non-digit values.
    const stringLength = cleanString.length; // length of input

    let formattedDate = cleanString,
      mm = cleanString.slice(0, 2), // month
      dd = cleanString.slice(2, 4), // day
      yyyy = cleanString.slice(4, 8); // year

    if (mm !== "") {
      mm = checkIfInRange(mm, 12); // make sure  <= 12
    }

    if (stringLength < 3) return mm;

    dd = checkIfInRange(dd, 31); // check if

    if (stringLength < 5) {
      formattedDate = `${mm}/${dd}`;
      return formattedDate;
    }

    if (yyyy.length === 4) {
      formattedDate = checkIfValidDate(mm, dd, yyyy);
    } else {
      formattedDate = `${mm}/${dd}/${yyyy}`;
    }

    return formattedDate;
  };

  /**
   * Converts value of input to phone number format (XXX) XXX-XXXX
   * @param {String} inputValue - value of input field
   * @returns string
   */
  const formatPhoneNumber = (inputValue) => {
    if (!inputValue) return inputValue;

    const cleanedInput = inputValue.replace(/[^\d]/g, ""); // clean the input for any non-digit values.
    const inputLength = cleanedInput.length;

    let formattedPhone = cleanedInput,
      areaCode = cleanedInput.slice(0, 3),
      middle = cleanedInput.slice(3, 6),
      end = cleanedInput.slice(6, 10);

    if (inputLength < 4) return cleanedInput;

    if (inputLength < 7) {
      return `(${areaCode}) ${cleanedInput.slice(3)}`;
    }

    formattedPhone = `(${areaCode}) ${middle}-${end}`;

    return formattedPhone;
  };

  const handleInput = (e) => {
    const inputElem = e.target;
    const inputValue = e.target.value;
    const typePassed = type.toLowerCase();

    let formattedValue = inputValue; // set to input value if input type not passed

    if (typePassed === "tel" || typePassed === "phone") {
      // if input type phone passed
      formattedValue = formatPhoneNumber(inputValue);
    } else if (typePassed === "date") {
      // if input type date passed
      formattedValue = formatDate(inputValue);
    }

    inputElem.value = formattedValue;
  };

  return (
    <React.Fragment>
      {type === "phone" || type === "tel" ? (
        <Input
          {...props}
          className={className}
          id={id}
          name={name}
          onChange={(e) => {
            handleInput(e);
            onChange(e);
          }}
          pattern="^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$"
          placeholder={
            typeof placeholder === "undefined" ? "(999) 999-9999" : placeholder
          }
          title="(XXX) XXX-XXXX"
          required={required}
          type="tel"
        />
      ) : (
        <Input
          {...props}
          className={className}
          id={id}
          name={name}
          title="MM/DD/YYYY"
          placeholder={
            typeof placeholder === "undefined" ? "MM/DD/YYYY" : placeholder
          }
          required={required}
          onChange={(e) => {
            handleInput(e);
            onChange(e);
          }}
          type="text"
        />
      )}
    </React.Fragment>
  );
};

AutoFormatInput.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  type: PropTypes.string,
};

AutoFormatInput.defaultProps = {
  type: "tel",
  required: false,
  onChange: () => {},
};

export default AutoFormatInput;
