/** @jsx jsx */
import { graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Box, Container, Flex, Heading, jsx } from "theme-ui";
import Form from "../components/Form";
import IconLink from "../components/IconLink";
import Layout from "../components/Layout";
import LinkButton from "../components/LinkButton";
import Seo from "../components/Seo";

const Contact = ({ data }) => {
  const mapImage = getImage(data.MapImage.childImageSharp.gatsbyImageData);

  return (
    <Layout
      contactBackground={data.ContactBackground.childImageSharp.gatsbyImageData}
      headerBannerText="Contact us"
      headerBgImage={data.HeroImage.childImageSharp.gatsbyImageData}
      headerFancyBanner={false}
      headerSmallHero={true}
    >
      <Seo title="Contact Us" />

      <Box
        as="section"
        sx={{
          backgroundColor: "darkBlue",
          backgroundImage: `url(${data.TopDotsRight.publicURL})`,
          backgroundPosition: "top right",
          backgroundRepeat: "no-repeat",
          backgroundSize: ["50%", null, "33%"],
          color: "textWhite",
          pt: [12, null, 11],
          pb: [8, null, 11],
        }}
      >
        <Container>
          <Box
            sx={{
              maxWidth: ["100%", null, "60%"],
              mb: 6,
            }}
          >
            <Heading as="h1" variant="styles.fancyUnderline">
              Schedule an appointment or inspection with Legacy Repair today!
            </Heading>

            <p sx={{ mt: 6 }}>
              If you would like Legacy Repair to inspect your roof, please fill
              out the contact form below, complete our brief estimate
              questionnaire, or simply give us a call. We will send a technician
              to inspect your roof and offer an expert’s opinion on the size and
              scope of your job.
            </p>

            <p>
              After you submit your information, we will contact you within
              24-48 hours to schedule your service visit and estimate.
            </p>

            <p>
              We charge a $125 flat fee for service visits. Once you schedule
              your repair, we will waive this fee by putting it towards your
              overall repair cost.
            </p>
          </Box>

          <Form />
        </Container>
      </Box>

      <Box
        id="visit-us"
        as="section"
        sx={{
          py: [10, null, 13],
          backgroundImage: `url(${data.BottomDotsRight.publicURL})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "bottom right",
          backgroundSize: ["50%", null, "30%"],
        }}
      >
        <Container>
          <Box
            sx={{
              textAlign: "center",
              mb: [5, null, 8],
            }}
          >
            <Heading as="h1" variant="styles.fancyUnderline">
              Servicing All of Central Arizona
            </Heading>
          </Box>

          <Flex
            sx={{
              alignItems: "center",
              flexWrap: "wrap",
              justifyContent: ["center", null, "space-between"],
            }}
          >
            <Box
              sx={{
                flex: ["0 0 100%", null, "0 0 55%"],
              }}
            >
              <GatsbyImage
                image={mapImage}
                alt="Map showing location of legacy roofing headquarters."
                sx={{
                  verticalAlign: "middle",
                }}
              />
            </Box>
            <Box
              sx={{
                flex: ["0 0 100%", null, "0 0 35%"],
              }}
            >
              <p sx={{ textAlign: ["center", null, "left"] }}>
                Legacy Repair - Legacy Roofing LLC
                <br />
                <a
                  href="https://goo.gl/maps/G4VbZuoHefi41tvn6"
                  rel="noopener noreferrer "
                  target="_blank"
                  sx={{
                    color: "black",
                    textDecoration: "none",
                    ":hover": {
                      textDecoration: "underline",
                    },
                  }}
                >
                  6635 W. Happy Valley Rd. Ste A 104-100
                  <br />
                  Glendale, AZ 852310
                </a>
              </p>

              <ul
                sx={{
                  listStyleType: "none",
                  mx: ["auto", null, 0],
                  p: 0,
                  width: "fit-content",
                }}
              >
                <li>
                  <IconLink
                    href="tel:623-303-0537"
                    imageAlt="phone icon"
                    imageSrc={data.PhoneIcon.publicURL}
                    large={true}
                    linkText="623-303-0537"
                    sx={{
                      mb: 4,
                    }}
                  />
                </li>
                <li>
                  <IconLink
                    href="mailto:info@legacyroof.repair"
                    imageAlt="Email Icon"
                    imageSrc={data.EmailIcon.publicURL}
                    large={true}
                    linkText="info@legacyroof.repair"
                  />
                </li>
              </ul>

              <div
                sx={{
                  textAlign: ["center", null, "left"],
                  mt: 5,
                }}
              >
                <LinkButton to="/about">More About Us</LinkButton>
              </div>
            </Box>
          </Flex>
        </Container>
      </Box>
    </Layout>
  );
};

export const query = graphql`
  query {
    HeroImage: file(relativePath: { eq: "contact-hero.jpg" }) {
      id
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          quality: 100
          webpOptions: { quality: 90 }
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    MapImage: file(relativePath: { eq: "map.jpg" }) {
      id
      childImageSharp {
        gatsbyImageData(
          width: 800
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    TopDotsRight: file(relativePath: { eq: "top-dots-right.svg" }) {
      id
      publicURL
    }
    PhoneIcon: file(relativePath: { eq: "phone.svg" }) {
      id
      publicURL
    }
    EmailIcon: file(relativePath: { eq: "email.svg" }) {
      id
      publicURL
    }
    BottomDotsRight: file(relativePath: { eq: "bottom-dots-right.svg" }) {
      id
      publicURL
    }
    ContactBackground: file(relativePath: { eq: "footer-bg.jpg" }) {
      id
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          quality: 100
          webpOptions: { quality: 90 }
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
  }
`;

export default Contact;
