/** @jsx jsx */
import { useForm } from "@formspree/react";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import {
  Box,
  Button,
  Close,
  Heading,
  Input,
  Label,
  Textarea,
  jsx,
} from "theme-ui";
import AutoFormatInput from "./AutoFormatInput";
import FormDatePicker from "./FormDatePicker";

const Form = ({ className }) => {
  const [modalActive, setModalActive] = useState(false);
  const [state, handleSubmit] = useForm("xvoyowra");

  useEffect(() => {
    if (state.succeeded === true) {
      setModalActive(true);
    }
  }, [state.succeeded]);

  return (
    <div className={className}>
      <form
        onSubmit={(e) => {
          handleSubmit(e);
          e.target.reset();
        }}
      >
        <ul
          sx={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-between",
            listStyleType: "none",
            m: 0,
            p: 0,
            li: {
              flex: ["0 0 100%", null, "0 0 49%"],
              width: "100%",
              "&:not(:first-of-type)": {
                mt: 3,
              },
              "&:nth-of-type(2)": {
                mt: [3, null, 0],
              },
              "&:not(:last-of-type)": {
                // flex: ["0 0 100%", null, "0 0 49%"],
              },
            },
          }}
        >
          <li>
            <Label htmlFor="first-name">first name*</Label>
            <Input
              id="first-name"
              name="first-name"
              placeholder="Type here"
              required
              type="text"
            />
          </li>
          <li>
            <Label htmlFor="last-name">Last name*</Label>
            <Input
              id="first-name"
              name="first-name"
              placeholder="Type here"
              required
              type="text"
            />
          </li>
          <li>
            <Label htmlFor="phone-number">Phone Number*</Label>
            <AutoFormatInput
              id="phone-number"
              name="phone-number"
              required={true}
              type="tel"
            />
          </li>
          <li>
            <Label htmlFor="email">Email</Label>
            <Input
              id="email"
              name="email"
              placeholder="Type here"
              type="email"
            />
          </li>
          <li>
            <Label htmlFor="address">address</Label>
            <Input
              id="address"
              name="address"
              placeholder="Type here"
              type="text"
            />
          </li>
          <li>
            <Label htmlFor="city">city</Label>
            <Input id="city" name="city" placeholder="Type here" type="text" />
          </li>
          <li>
            <Label htmlFor="address">zip code*</Label>
            <Input
              id="zip-code"
              name="zip-code"
              pattern="[0-9]*"
              placeholder="Type here"
              required
              type="text"
            />
          </li>
          <li>
            <Label htmlFor="preferred-appointment-date">
              preferred appointment date
            </Label>
            <FormDatePicker
              id="preferred-appointment-date"
              name="preferred-appointment-date"
            />
          </li>
          <li>
            <Label htmlFor="comments-and-questions">
              Comments and questions
            </Label>
            <Textarea
              id="comments-and-questions"
              name="comments-and-questions"
              placeholder="Type here"
              rows={4}
            />
          </li>
          <li sx={{ pt: [0, null, "36px"] }}>
            <ul sx={{ listStyleType: "none", m: 0, p: 0 }}>
              <li>*Required Fields</li>
              <li>Residents of Arizona only</li>
            </ul>
          </li>
        </ul>

        <Box
          sx={{
            textAlign: "center",
            mt: [5, null, 7],
          }}
        >
          <Button
            type="submit"
            variant="submit"
            sx={{
              px: 6,
            }}
          >
            submit
          </Button>
        </Box>
      </form>

      <aside
        sx={{
          alignItems: "center",
          backgroundColor: "rgba(0, 0, 0, 0.65)",
          display: modalActive ? "flex" : "none",
          height: "100vh",
          justifyContent: "center",
          left: 0,
          position: "fixed",
          top: 0,
          width: "100%",
          zIndex: 15,
        }}
      >
        <div
          sx={{
            alignItems: "center",
            backgroundColor: "background",
            display: "flex",
            flex: ["0 0 90%", "0 0 70%", "0 0 40%"],
            justifyContent: "center",
            position: "relative",
            px: [4],
            py: [5, 7],
          }}
        >
          <div sx={{ textAlign: "center" }}>
            <div
              sx={{
                alignItems: "center",
                backgroundColor: "secondary",
                borderRadius: "50%",
                display: "flex",
                height: [90],
                justifyContent: "center",
                mx: "auto",
                width: [90],
              }}
            >
              <div
                sx={{
                  borderColor: "textWhite",
                  borderStyle: ["solid"],
                  borderWidth: ["0 0 10px 10px"],
                  height: [25],
                  transform: "rotate(-45deg)",
                  width: [50],
                }}
              ></div>
            </div>

            <Heading as="h1" variant="styles.h3" sx={{ mt: 5 }}>
              thank you!
            </Heading>

            <p sx={{ mb: 0, color: "text" }}>
              Thank you for reaching out to us! We've got your request and will
              be in touch with you soon.
            </p>
          </div>

          <Close
            type="button"
            onClick={() => setModalActive(false)}
            sx={{
              position: "absolute",
              right: "15px",
              top: "15px",
            }}
          />
        </div>
      </aside>
    </div>
  );
};

Form.propTypes = {
  className: PropTypes.string,
};

export default Form;
